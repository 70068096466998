<script setup lang="ts">
import { computed, defineAsyncComponent, provide, reactive, ref, onMounted } from 'vue'
import router from './router/router'
import { useViewport } from './composables/viewport'
import { useIdentityStore } from './store/identityStore'
import { storeToRefs } from 'pinia'

import packageApp from '../package.json'

const appVersion = ref(packageApp.version)

function clearCacheAndReload (): void {
  console.log('Clearing cache and hard reloading...')
  if (caches != null) {
    caches.keys().then((names) => {
      for (const name of names) void caches.delete(name)
    }).catch(err => { console.log(err) })
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  window.location.reload(true)
}

function checkVersionMismatch (latestVersion: string, currentVersion: string): boolean {
  const latestVersionArray = latestVersion.split(/\./g)
  const currentVersionArray = currentVersion.split(/\./g)
  while (latestVersionArray.length > 0 || currentVersionArray.length > 0) {
    const a = Number(latestVersionArray.shift())
    const b = Number(currentVersionArray.shift())
    if (a === b) continue
    return a > b || isNaN(b)
  }
  return false
}

onMounted(() => {
  fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
    .then(async (response) => response)
    .then(async (meta) => {
      const a = await meta.json()

      const latestVersion = a.version as string
      const currentVersion = appVersion.value
      const shouldForceRefresh = checkVersionMismatch(latestVersion, currentVersion)

      if (shouldForceRefresh) {
        console.log(`New version - ${latestVersion}. Available, need to force refresh`)
        clearCacheAndReload()
      } else {
        console.log(`Already latest version - ${latestVersion}. No refresh required.`)
      }
    }).catch(err => { console.log(err) })
})

const IdentityStore = useIdentityStore()
const { getIdentityShowPopup } = storeToRefs(IdentityStore)

const LayoutLanding = defineAsyncComponent(async () => await import('@/components/landing/LayoutLanding.vue'))
const LayoutPlatfrom = defineAsyncComponent(async () => await import('@/components/platform/LayoutPlatform.vue'))
const isPlatform = computed(() => {
  return router.currentRoute.value.meta.isPlatform
})

// Alerts

const XAlert = defineAsyncComponent(async () => await import('@/components/elements/XAlert/XAlert.vue'))
const AlertParams = reactive({
  visible: false,
  props: {
    content: '',
    type: 'info'
  }
})
interface AlertParamsType {
  content: string
  type: string
}
let XAlertHideTimeout: ReturnType<typeof setTimeout>
function updateAlertParams (params: AlertParamsType): void {
  AlertParams.props.content = params.content
  AlertParams.props.type = params.type
}
function resetAlertParams (): void {
  AlertParams.props.content = ''
  AlertParams.props.type = 'info'
}
function showXAlert (params: AlertParamsType): void {
  updateAlertParams(params)
  AlertParams.visible = true
  XAlertHideTimeout = setTimeout(hideXAlert, 4000)
}
function hideXAlert (): void {
  if (XAlertHideTimeout != null) {
    clearTimeout(XAlertHideTimeout)
  }

  AlertParams.visible = false
  resetAlertParams()
}
provide('showXAlert', showXAlert)

const { ViewportWidth } = useViewport()

const XAlertTransitionName = computed(() => {
  if (ViewportWidth.value < 900) return 'bottom-viewport-slide'
  return 'right-viewport-slide'
})

const XPopup = defineAsyncComponent(async () => await import('@/components/elements/XPopup/XPopup.vue'))
const isXPopupVisible = ref(false)

function showXPopup (): void {
  if (getIdentityShowPopup.value === true) isXPopupVisible.value = true
}
provide('showXPopup', showXPopup)

const CookieBanner = defineAsyncComponent(async () => await import('@/components/common/CookieBanner.vue'))

const showCookiesBanner = ref(false)

onMounted(() => {
  const cookies = localStorage.getItem('_xr_cookies')
  if (cookies == null) showCookiesBanner.value = true
})

</script>

<template>
  <LayoutPlatfrom v-if="isPlatform" />
  <LayoutLanding v-else />

  <Transition
    :name="XAlertTransitionName"
    appear
  >
    <XAlert
      v-if="AlertParams.visible"
      v-bind="AlertParams.props"
      @action:close="hideXAlert"
    />
  </Transition>
  <Transition
    name="bottom-viewport-slide-popup"
    appear
  >
    <XPopup
      v-if="isXPopupVisible"
      @action:close="isXPopupVisible = false"
    />
  </Transition>

  <CookieBanner
    v-if="showCookiesBanner"
    @action-close="showCookiesBanner=false"
  />
</template>

<style scoped>

</style>
