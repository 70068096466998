export default function gTagPushPageEvent (id: string): void {
  const dataLayer = window.dataLayer ?? []

  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('_xray_cs='))
    ?.split('=')[1]

  if (cookieValue !== 'false') {
    dataLayer.push({ event: 'uid', user_id: id })
  }
}
