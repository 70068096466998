export default function ParseJwt (item: string): Token {
  const base64Url = item.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
interface Token {
  email: string
  exp: number
  first_name: string
  is_api_available: boolean
  is_confirmed: boolean
  is_new: boolean
  is_paid: boolean
  last_name: string
  oauth_provider: null | string
  popups: Record<string, unknown>
  sub_info: Record<string, unknown>
  surveys: Record<string, unknown>
  user_id: string
}
