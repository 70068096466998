import { ref, onMounted, onUnmounted } from 'vue'
import type { Ref } from 'vue'

export function useViewport (): { ViewportWidth: Ref<number>, ViewportHeight: Ref<number> } {
  const ViewportWidth = ref(0)
  const ViewportHeight = ref(0)

  function update (): void {
    ViewportWidth.value = window.innerWidth
    ViewportHeight.value = window.innerHeight
  }

  onMounted(() => {
    window.addEventListener('resize', update)
    update()
  })
  onUnmounted(() => { window.removeEventListener('resize', update) })

  return { ViewportWidth, ViewportHeight }
}
