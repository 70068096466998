import routes from '@/router/routes'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior (to, from, savedPosition) {
    const toPricingChilds = to.name === 'Subscription Plans' || to.name === 'Credits Packages'
    const fromPricingChilds = from.name === 'Subscription Plans' || from.name === 'Credits Packages'
    if (fromPricingChilds && toPricingChilds) {
      return
    }
    return { top: 0 }
  },
  strict: true,
  sensitive: true
})

export default router
